import { Subject, BehaviorSubject } from 'rxjs';
import { HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { HubConnectionState } from '@aspnet/signalr/dist/esm/HubConnection';
import * as i0 from "@angular/core";
var WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS = 2000;
var NotificationSignalRService = /** @class */ (function () {
    function NotificationSignalRService() {
        this.messageReceived$ = new Subject();
        this.connectionEstablished$ = new BehaviorSubject(false);
        this.notificationData$ = new Subject();
        this.singalrNotification = abp.setting.get('ServerRootAddress').indexOf('/') === abp.setting.get('ServerRootAddress').length - 1 ? 'signalr-notification' : '/signalr-notification';
        this.createConnection();
        this.registerOnServerEvents();
        this.startConnection();
    }
    NotificationSignalRService.prototype.createConnection = function () {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(abp.setting.get('ServerRootAddress') + this.singalrNotification, {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        })
            .configureLogging(signalR.LogLevel.Information)
            .build();
    };
    NotificationSignalRService.prototype.startConnection = function () {
        var _this = this;
        if (this.hubConnection.state === HubConnectionState.Connected) {
            return;
        }
        setTimeout(function () {
            _this.hubConnection.start().then(function () {
                console.log('Hub connection started');
                _this.connectionEstablished$.next(true);
            }, function (error) { return console.error(error); });
        }, WAIT_UNTIL_ASPNETCORE_IS_READY_DELAY_IN_MS);
    };
    NotificationSignalRService.prototype.registerOnServerEvents = function () {
        var _this = this;
        this.hubConnection.on('NotificationReceived', function (data) {
            _this.notificationData$.next(data);
        });
    };
    NotificationSignalRService.ngInjectableDef = i0.defineInjectable({ factory: function NotificationSignalRService_Factory() { return new NotificationSignalRService(); }, token: NotificationSignalRService, providedIn: "root" });
    return NotificationSignalRService;
}());
export { NotificationSignalRService };
