import { ActivatedRouteSnapshot, CanActivate, Router, ResolveEnd, NavigationExtras } from '@angular/router';
import { AzureInsightsService } from '@shared/AzureLogging/azure-insights.service';
import { AuthService } from './auth.service';
import { User, Profile } from 'oidc-client';
import { CommonhelperService } from '@app/shared/services/commonhelper.service';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/AzureLogging/azure-insights.service";
import * as i2 from "@angular/router";
import * as i3 from "./auth.service";
import * as i4 from "../../services/commonhelper.service";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(insight, router, authService, _commonHelper) {
        this.insight = insight;
        this.router = router;
        this.authService = authService;
        this._commonHelper = _commonHelper;
    }
    AuthGuard.prototype.canActivate = function (route) {
        if (route._routerState.url.indexOf('auth-callback') >= 0 && route._routerState.url.indexOf('check-auth') >= 0) {
        }
        else {
            localStorage.setItem('nextLink', route._routerState.url);
        }
        if (this.authService.isLoggedIn()) {
            this.user = this.authService.getClaims();
            this._commonHelper.init(this.user);
            this.insight.setAuthenticatedUserId(this.user.profile.sub);
            this.logPageView();
            return true && this.checkIfRouteActive(route);
        }
        this.logPageView();
        var navigationExtras = {
            state: {
                next: route._routerState.url
            }
        };
        localStorage.setItem('nextLink', route._routerState.url);
        this.router.navigate(['/check-auth']);
        // this.authService.startAuthentication(route._routerState.url);
        return false;
    };
    AuthGuard.prototype.checkIfRouteActive = function (route) {
        if (this.authService.userPermission) {
            if (this.authService.userPermission.indexOf('PolicyUser1') >= 0 || this.authService.userPermission.indexOf('TrialUser1') >= 0) {
                return true;
            }
            return this.authService.userPermission.indexOf(route.data.permission) >= 0;
        }
        else {
            return false;
        }
    };
    AuthGuard.prototype.GetUserInfo = function (callback, forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        this.authService.getUser(function (res) {
            _this.user.profile = res;
            _this._commonHelper.init(_this.user);
            callback(res);
        }, forceUpdate);
        this.user = this.authService.getClaims();
        this._commonHelper.init(this.user);
    };
    AuthGuard.prototype.logPageView = function () {
        var _this = this;
        this.routerSubscription = this.router.events.pipe(filter(function (event) { return event instanceof ResolveEnd; }))
            .subscribe(function (event) {
            //const activatedComponent = this.getActivatedComponent(event.state.url);
            _this.insight.logPageView(event.state.url, event.state.url);
            //if (activatedComponent) {
            //    this.insight.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
            //}
        });
    };
    AuthGuard.prototype.getActivatedComponent = function (snapshot) {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }
        return snapshot.component;
    };
    AuthGuard.prototype.getRouteTemplate = function (snapshot) {
        var path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }
        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }
        return path;
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AzureInsightsService), i0.inject(i2.Router), i0.inject(i3.AuthService), i0.inject(i4.CommonhelperService)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
