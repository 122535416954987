// "Production" enabled environment

export const environment = {
    production: true,
    hmr: false,
    appConfig: 'appconfig.production.json',
    appInsights: {
        instrumentationKey: '7a266488-2276-4746-8d74-0fb91cc52e5f'
    }
};
