import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,

    ActivatedRoute,
    ResolveEnd,
    NavigationExtras
} from '@angular/router';
import { Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { AzureInsightsService } from '@shared/AzureLogging/azure-insights.service';
import { AuthService, getClientSettings } from './auth.service';
import { UserManager, User, Profile } from 'oidc-client';
import { IdpPermissionCheckerService } from '../idp-permission-checker.service';
import { CommonhelperService } from '@app/shared/services/commonhelper.service';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    public user: User;
    public anonymousAccessTokn: string;
    routerSubscription: any;
    constructor(
        private insight: AzureInsightsService,
        private router: Router,
        private authService: AuthService,
        private _commonHelper: CommonhelperService
    ) { }

    canActivate(route: any): boolean {
        if (route._routerState.url.indexOf('auth-callback') >= 0 && route._routerState.url.indexOf('check-auth') >= 0) {

        } else {
            localStorage.setItem('nextLink', route._routerState.url);
        }
        if (this.authService.isLoggedIn()) {
            this.user = this.authService.getClaims();
            this._commonHelper.init(this.user);
            this.insight.setAuthenticatedUserId(this.user.profile.sub);
            this.logPageView();
            return true && this.checkIfRouteActive(route);
        }
        this.logPageView();
        let navigationExtras: NavigationExtras = {
            state: {
                next: route._routerState.url
            }
        };
        localStorage.setItem('nextLink', route._routerState.url);
        this.router.navigate(['/check-auth']);
        // this.authService.startAuthentication(route._routerState.url);
        return false;
    }

    checkIfRouteActive(route): boolean {
        if (this.authService.userPermission) {
            if (this.authService.userPermission.indexOf('PolicyUser1') >= 0 || this.authService.userPermission.indexOf('TrialUser1') >= 0) {
                return true;
            }

            return this.authService.userPermission.indexOf((route as any).data.permission) >= 0;
        } else {
            return false;
        }
    }

    public GetUserInfo(callback: any, forceUpdate: boolean = false): void {
        this.authService.getUser((res: Profile) => {
            this.user.profile = res;
            this._commonHelper.init(this.user);
            callback(res);
        }, forceUpdate);

        this.user = this.authService.getClaims();
        this._commonHelper.init(this.user);
    }

    logPageView() {
        this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd))
            .subscribe((event: ResolveEnd) => {
                //const activatedComponent = this.getActivatedComponent(event.state.url);
                this.insight.logPageView(event.state.url, event.state.url);
                //if (activatedComponent) {
                //    this.insight.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
                //}
            });
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {

        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}
